import { render, staticRenderFns } from "./input-item.vue?vue&type=template&id=ce1f70aa&scoped=true&"
import script from "./input-item.vue?vue&type=script&lang=js&"
export * from "./input-item.vue?vue&type=script&lang=js&"
import style0 from "./input-item.vue?vue&type=style&index=0&id=ce1f70aa&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ce1f70aa",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/data/apps/jenkins/workspace/新海外前端/fintopia-web-build-deploy@2/prod-mex-huawei-ssr/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('ce1f70aa')) {
      api.createRecord('ce1f70aa', component.options)
    } else {
      api.reload('ce1f70aa', component.options)
    }
    module.hot.accept("./input-item.vue?vue&type=template&id=ce1f70aa&scoped=true&", function () {
      api.rerender('ce1f70aa', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/common/component/cm-form/input-item.vue"
export default component.exports