var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "row-center" },
        [
          _c("input-item", {
            attrs: {
              value: _vm.code,
              type: "tel",
              format: {
                format: function (val) {
                  return val.replace(/[^\d]/g, "")
                },
              },
              maxlength: 6,
              "use-cleave": {
                blocks: [6],
              },
              label: "Código de Verificación",
            },
            on: { input: _vm.handleInputCode },
            scopedSlots: _vm._u([
              {
                key: "after",
                fn: function () {
                  return [
                    _vm.second
                      ? _c(
                          "div",
                          {
                            staticClass: "countdown",
                            style: {
                              "padding-top": _vm.ua.isAndroid ? "0.02rem" : "0",
                            },
                          },
                          [
                            _vm._v(
                              "\n                    " +
                                _vm._s(_vm.second) +
                                "\n                "
                            ),
                          ]
                        )
                      : _c("img", {
                          staticClass: "re-send",
                          attrs: { src: require("./img/send.svg") },
                          on: {
                            click: function ($event) {
                              return _vm.handleSendSms()
                            },
                          },
                        }),
                  ]
                },
                proxy: true,
              },
            ]),
          }),
        ],
        1
      ),
      _vm._v(" "),
      _vm.showModal
        ? _c(
            "ec-overlay",
            { staticClass: "captcha-modal", attrs: { visible: _vm.showModal } },
            [
              _c("div", { staticClass: "modal-box" }, [
                _c("div", { staticClass: "top-icon" }, [
                  _c("img", {
                    staticClass: "close",
                    attrs: { src: require("./img/close.png") },
                    on: {
                      click: function ($event) {
                        _vm.showModal = false
                      },
                    },
                  }),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "desc" }, [
                  _vm._v(
                    "\n                Ingrese el código de verificación de 4 dígitos en la figura\n            "
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "captcha-image" }, [
                  _c("img", {
                    staticClass: "code",
                    attrs: { src: _vm.captchaImg },
                  }),
                  _vm._v(" "),
                  _c("img", {
                    staticClass: "refresh",
                    attrs: { src: require("./img/refresh.png") },
                    on: { click: _vm.fetchCaptchaImg },
                  }),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "captcha-input" },
                  [
                    _c("vcode-input", {
                      ref: "vcodeInput",
                      on: {
                        finish: function (code) {
                          return _vm.handleChangeCaptchaCode(code, "noinit")
                        },
                      },
                    }),
                  ],
                  1
                ),
              ]),
            ]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }