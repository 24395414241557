import { render, staticRenderFns } from "./index.vue?vue&type=template&id=e227bb90&"
import script from "./index.vue?vue&type=script&lang=js&"
export * from "./index.vue?vue&type=script&lang=js&"
import style0 from "./index.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/data/apps/jenkins/workspace/新海外前端/fintopia-web-build-deploy@2/prod-mex-huawei-ssr/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('e227bb90')) {
      api.createRecord('e227bb90', component.options)
    } else {
      api.reload('e227bb90', component.options)
    }
    module.hot.accept("./index.vue?vue&type=template&id=e227bb90&", function () {
      api.rerender('e227bb90', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/common/component/cm-otp/index.vue"
export default component.exports