/*
 * @Author: wuhuiwang 
 * @Date: 2024-03-26 10:57:22 
 * @Last Modified by:   wuhuiwang 
 * @Last Modified time: 2024-03-26 10:57:22 
 */

export default {
    mounted() {
        let width = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
        width = width > 550 ? 550 : width;
        const fz = Math.floor((width * 100000) / 37.5) / 10000;
        document.getElementsByTagName('html')[0].style.cssText = `font-size: ${fz}px`;
        const realfz = Math.floor(
            +window.getComputedStyle(document.getElementsByTagName('html')[0]).fontSize.replace('px', '') * 10000
        ) / 10000;
        if (fz !== realfz) {
            document.getElementsByTagName('html')[0].style.cssText = `font-size: ${fz * (fz / realfz)}px`;
        }
    }
};
