<!-- @Author: wuhuiwang -->
<!-- @Date: 2023-8-23 17:04:46 -->
<!-- @Last Modified by: wangwuhui -->
<!-- @Last Modified time: 2024-01-30 16:53:32 -->

<template>
    <div>
        <div class="row-center">
            <input-item
                :value="code"
                type="tel"
                :format="{
                    format: val => val.replace(/[^\d]/g, '')
                }"
                :maxlength="6"
                :use-cleave="{
                    blocks: [6]
                }"
                label="Código de Verificación"
                @input="handleInputCode"
            >
                <template #after>
                    <div
                        v-if="second"
                        class="countdown"
                        :style="{'padding-top': ua.isAndroid ? '0.02rem' : '0'}"
                    >
                        {{ second }}
                    </div>
                    <img
                        v-else
                        src="./img/send.svg"
                        class="re-send"
                        @click="handleSendSms()"
                    >
                </template>
            </input-item>
        </div>

        <ec-overlay
            v-if="showModal"
            :visible="showModal"
            class="captcha-modal"
        >
            <div class="modal-box">
                <div class="top-icon">
                    <img
                        class="close"
                        src="./img/close.png"
                        @click="showModal = false"
                    >
                </div>
                <div class="desc">
                    Ingrese el código de verificación de 4 dígitos en la figura
                </div>
                <div class="captcha-image">
                    <img
                        class="code"
                        :src="captchaImg"
                    >
                    <img
                        class="refresh"
                        src="./img/refresh.png"
                        @click="fetchCaptchaImg"
                    >
                </div>
                <div class="captcha-input">
                    <vcode-input
                        ref="vcodeInput"
                        @finish="code => handleChangeCaptchaCode(code, 'noinit')"
                    />
                </div>
            </div>
        </ec-overlay>
    </div>
</template>

<script>
/* eslint-disable no-unused-expressions */
import ua from 'ssr-common/util/webview/ua-parsed';

import EcOverlay from 'easycash/common/component/ec-overlay';
import vcodeInput from 'easycash/common/component/ec-vcode-input';
import User from 'easycash/common/resource/user';

import InputItem from 'olacred/common/component/cm-form/input-item';
import Api from 'olacred/common/resource/user.js';

export default {
    components: {
        InputItem,
        EcOverlay,
        vcodeInput
    },

    props: {
        needCheckPhoneNumber: {
            type: Boolean
        },

        requestData: {
            type: Object,
            default: () => ({})
        }
    },

    data() {
        return {
            ua,
            code: '',
            timer: null,
            second: '',
            showModal: false,
            captchaKey: '',
            captchaImg: ''
        };
    },

    methods: {
        handleInputCode(code) {
            this.code = code;
            this.$emit('input', code);
        },

        handleChangeCaptchaCode(code, type) {
            if (code.length === 4) {
                this.handleSendSms(code, type);
            }
        },

        async handleSendSms(code, type) {
            if (this.second) return;

            this.inputPhoneNumber = this.requestData.mobileNumber || '';
            if (this.needCheckPhoneNumber) {
                if (this.inputPhoneNumber?.length !== 10) {
                    this.$toast.fail('Por favor ingresa un número de celular válido en México.');

                    return;
                }

                const {oldMobileNumber, userId} = this.$route.query || {};
                if (type !== 'noinit') {
                    await this.handleMobileApply(oldMobileNumber, userId);
                }
            }

            const data = {
                mobileNumber: this.$route.query.mobileNumber || this.inputPhoneNumber || '',
                verificationPurpose: 'DELETE_USER',
                notifType: 'SMS',
                supportType: 'SMS',
                ...this.requestData
            };
            if (code) {
                data.captcha = code;
                data.captchaKey = this.captchaKey;
            }

            const {errMsg, errCode} = await this.getVerificationCode(data);
            if (errCode === undefined) {
                return;
            }

            if (!errCode) {
                this.showModal = false;
                this.startTimer();
            } else if (errCode === 30017) {
                await this.fetchCaptchaImg();
            } else if (errCode === 3009) {
                this.$refs.vcodeInput.init();
                await this.fetchCaptchaImg();
                this.$toast.fail(errMsg);
            } else {
                this.$toast.fail(errMsg);
            }
        },

        async getVerificationCode(data) {
            const {
                data: {
                    body,
                    status: {detail, code: errCode} = {}
                } = {}
            } = await User.sendVerificationWithoutCaptcha(data, {hideToast: true}).catch(err => err);

            return {body: body ?? {}, errMsg: detail, errCode};
        },

        async fetchCaptchaImg() {
            const {data: {body: captchaKey}} = await User.generateCaptcha();
            this.captchaKey = captchaKey;
            this.captchaImg = await User.getCaptchaImage({captchaKey});
            this.showModal = true;
        },

        async handleMobileApply(oldMobileNumber, userId) {
            if (this.needCheckPhoneNumber) {
                const {data: {body: {applyId} = {}} = {}} = await Api.initMobileApply({
                    oldMobileNumber,
                    newMobileNumber: this.inputPhoneNumber,
                    userId
                });
                this.$emit('sendApplyId', applyId);
            }
        },

        handleResetCode() {
            this.$refs.vcodeInputSix?.handleReset();
            this.second = '';
            clearInterval(this.timer);
            this.timer = null;
        },

        startTimer() {
            if (this.timer) return;
            this.second = 60;
            this.timer = setInterval(() => {
                this.second -= 1;
                if (this.second < 1) {
                    clearInterval(this.timer);
                    this.timer = null;
                }
            }, 1000);
        }
    }
};
</script>
<style lang='scss'>
.row-center {
    .countdown {
        width: 0.24rem;
        height: 0.24rem;
        background: #dedede;
        border-radius: 50%;
        font-size: 0.12rem;
        color: $base-white;
        text-align: center;
        margin-bottom: 0.05rem;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .re-send {
        width: 0.24rem;
        height: 0.24rem;
        margin-bottom: 0.05rem;
    }
}

.captcha-modal {
    z-index: 99;
    justify-content: unset !important;

    .modal-wrapper {
        width: 80vw;
        padding: 0.32rem 0.2rem 0.4rem;
        position: relative;
        box-sizing: border-box;
        background-color: #fff;
        box-shadow: 0 0 0.2rem 0 rgba(0, 0, 0, 0.2);
        border-radius: 0.08rem;
        text-align: left;
        top: 1rem;

        .close {
            position: absolute;
            width: 0.24rem;
            height: 0.24rem;
            cursor: pointer;
            top: 0.08rem;
            right: 0.08rem;
        }

        .desc {
            margin-top: 0.15rem;
            font-size: 0.16rem;
            font-family: Helvetica-Bold, Helvetica;
            font-weight: bold;
            color: $base-black;
            line-height: 0.22rem;
            text-align: center;
        }

        .captcha-image {
            display: flex;
            align-items: center;
            justify-content: center;
            position: relative;
            margin: 0.3rem 0 0.24rem;

            .code {
                width: 1.38rem;
                height: 0.56rem;
            }

            .refresh {
                position: absolute;
                right: 0.27rem;
                width: 0.24rem;
                height: 0.24rem;
                cursor: pointer;
            }
        }

        .captcha-input {
            .v-code {
                width: 1.06rem;
            }
        }
    }
}
</style>

