var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "btn",
      class: { "btn-disabled": _vm.disabled },
      style: {
        borderRadius: "0.08rem",
      },
      on: { click: _vm.handleSubmit },
    },
    [_vm._v("\n    " + _vm._s(_vm.text) + "\n")]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }