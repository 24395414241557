/*
 * @Author: wuhuiwang
 * @Date: 2023-08-15 19:30:09
 * @Last Modified by: wuhuiwang
 * @Last Modified time: 2024-03-26 11:01:37
 */

import Resource from '@yqg/resource';

import {getEurekaHost} from 'easycash/common/constant/config';

const api = {
    getHost: getEurekaHost,

    canDelete: {
        url: '/api/user/canDelete',
        method: 'get'
    },

    identityAndOtp: {
        url: '/api/secure/verify/identityAndOtp',
        method: 'post'
    },

    deleteApply: {
        url: '/api/user/deleteApply',
        method: 'post'
    },

    deleteApplyWithOutLogin: {
        url: '/api/user/deleteApplyWithOutLogin',
        method: 'post'
    },

    fetchUser: {
        url: '/api/v2/user/fetch',
        method: 'get'
    },

};

export default Resource.create(api);
