var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "otp" }, [
    _c("div", { staticClass: "tip" }, [
      _vm._v("\n        " + _vm._s(_vm.getI18n("otpTip")) + "\n    "),
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "mobile-wrap" },
      [
        _c("input-item", {
          attrs: {
            label: "Número de teléfono móvil",
            value: _vm.$route.query.mobileNumber,
            disabled: "",
          },
          scopedSlots: _vm._u([
            {
              key: "before",
              fn: function () {
                return [
                  _c("div", { staticClass: "prefix" }, [
                    _vm._v("\n                    +52\n                "),
                  ]),
                ]
              },
              proxy: true,
            },
          ]),
        }),
        _vm._v(" "),
        _c("cm-otp", {
          ref: "cmVcode",
          staticClass: "unsubscribe-otp",
          on: { input: _vm.handleFinishCode },
        }),
        _vm._v(" "),
        _c("cm-btn", {
          staticClass: "otp-btn",
          attrs: {
            text: _vm.getI18n("continue"),
            disabled: _vm.code.length < 6,
          },
          on: { submit: _vm.handleIdentify },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }