var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "van-overlay",
    _vm._b(
      {
        staticClass: "ec-overlay",
        class: { "z-index": _vm.addZIndex },
        style: _vm.overlayStyle,
        attrs: { "lock-scroll": _vm.lockScroll, show: _vm.visible },
        on: { click: _vm.handleClick },
      },
      "van-overlay",
      _vm.overlayProps,
      false
    ),
    [
      _c(
        "div",
        {
          staticClass: "modal-wrapper",
          style: _vm.wrapStyle,
          on: {
            click: function ($event) {
              $event.stopPropagation()
            },
          },
        },
        [_vm._t("default")],
        2
      ),
      _vm._v(" "),
      _vm.showClose
        ? _c("close-vc", {
            directives: [
              {
                name: "yqg-event",
                rawName: "v-yqg-event",
                value: _vm.closeBusinessEvents,
                expression: "closeBusinessEvents",
              },
            ],
            staticClass: "image",
            on: {
              click: function ($event) {
                return _vm.$emit("change", false)
              },
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }