<!-- @Author: wuhuiwang -->
<!-- @Date: 2024-3-26 10:55:01 -->
<!-- @Last Modified by: wangwuhui -->
<!-- @Last Modified time: 2024-03-26 11:34:43 -->

<template>
    <div class="delete-account">
        <img
            class="logo"
            src="./img/logo.png"
        >
        <div class="title">
            {{ getI18n('deleteTitle') }}
        </div>
        <input-item
            v-model="inputInfo.value"
            v-bind="inputInfo"
        >
            <template #before>
                <div class="prefix">
                    +52
                </div>
            </template>
        </input-item>
        <cm-btn
            class="submit-btn"
            :text="getI18n('deleteSubmit')"
            :disabled="inputInfo.value.length !== 10"
            @submit="handleSubmit"
        />
    </div>
</template>

<script>
import CmBtn from 'olacred/common/component/cm-btn';
import InputItem from 'olacred/common/component/cm-form/input-item';
import maxRem from 'olacred/common/mixin/max-rem';
export default {
    name: 'DeleteAccount',

    components: {CmBtn, InputItem},

    mixins: [maxRem],

    data() {
        return {
            isScroll: false,
            inputInfo: {
                value: '',
                type: 'tel',
                format: {
                    format: val => val.replace(/[^\d]/g, '')
                },
                maxlength: 10,
                useCleave: {
                    blocks: [10]
                },
                label: 'Número de teléfono móvil',
                clearable: true,
                autoComplete: 'off',
            },
        };
    },

    methods: {
        getI18n(text, params) {
            return this.$t(`webview.unsubscribe.${text}`, params);
        },

        handleSubmit() {
            this.$router.push({
                name: 'unsubscribe',
                query: {
                    mobileNumber: this.inputInfo.value
                }
            });
        }
    }
};
</script>

<style lang="scss" scoped>
@font-face {
    font-family: "HelveticaBold";
    src: url("~olacred/common/style/font-face/Helvetica-Bold.otf");
}

@font-face {
    font-family: "Helvetica";
    src: url("~olacred/common/style/font-face/Helvetica.ttf");
}

.delete-account {
    max-width: 550px;
    margin: 0 auto;

    .logo {
        width: auto;
        height: 0.26rem;
        margin: 0.8rem 0 0.48rem;
    }

    .title {
        font-family: Helvetica-Bold, Helvetica;
        font-size: 18px;
        font-weight: 700;
        line-height: 26.1px;
        text-align: left;
        color: $base-black;
        margin-bottom: 0.2rem;
    }

    .prefix {
        margin-right: 0.08rem;
    }

    .submit-btn {
        margin-top: 0.24rem;
        border-radius: 0.3rem;
        box-shadow: 0 2px 14px 0 rgba(0, 0, 0, 0.06);
    }
}
</style>
