/*
 * @Author: chengyuzhang
 * @Date: 2021-04-15 15:28:00
 * @Last Modified by: zhaoyang
 * @Last Modified time: 2023-10-18 15:14:34
 */

import Resource from '@yqg/resource';

import {getEurekaHost} from 'easycash/common/constant/config';

const api = {
    getHost: getEurekaHost,

    register: {
        url: '/api/user/union/register',
        method: 'post'
    },

    userStatus: {
        url: '/api/v5/cashloan/userStatus',
        method: 'get'
    },

    registerV2: {
        url: '/api/v2/user/union/register',
        method: 'post'
    },

    registerOrLogin: {
        url: '/api/v2/user/union/registerOrLogin',
        method: 'post'
    },

    generateCaptcha: {
        url: '/api/user/generateCaptcha',
        method: 'post'
    },

    getCaptchaImage: {
        url: '/api/user/getCaptchaImage/:captchaKey',
        method: 'get',
        link: true
    },

    sendVerification: {
        url: '/api/v2/mobile/sendVerification',
        method: 'post'
    },

    mobileNumberPreCheck: {
        url: '/api/user/union/mobileNumberPreCheck',
        method: 'get'
    },

    sendVerificationWithoutCaptcha: {
        url: '/api/mobile/sendVerificationWithoutCaptcha',
        method: 'post'
    },

    login: {
        url: '/api/v2/user/union/login',
        method: 'post'
    },

    getAreaDataList: {
        url: '/api/user/getAreaDataList',
        method: 'get'
    },

    getListDeleteReason: {
        url: '/api/user/listDeleteReasons',
        method: 'get'
    },

    questionnaire: {
        url: '/api/user/questionnaire',
        method: 'post'
    },

    helpWish: {
        url: '/api/loan/activity/wish/help',
        method: 'post'
    }
};

export default Resource.create(api);
