<!-- @Author: chengyuzhang -->
<!-- @Date: 2021-8-6 10:53:53 -->
<!-- @Last Modified by: ruiwang -->
<!-- @Last Modified time: 2023-10-12 15:43:34 -->

<template>
    <van-overlay
        :lock-scroll="lockScroll"
        :show="visible"
        :style="overlayStyle"
        class="ec-overlay"
        :class="{'z-index': addZIndex}"
        v-bind="overlayProps"
        @click="handleClick"
    >
        <div
            class="modal-wrapper"
            :style="wrapStyle"
            @click.stop
        >
            <slot />
        </div>
        <close-vc
            v-if="showClose"
            v-yqg-event="closeBusinessEvents"
            class="image"
            @click="$emit('change', false)"
        />
    </van-overlay>
</template>

<script>
import {Overlay} from 'vant';
import {mapState} from 'vuex';

import EventUtil from 'easycash/common/util/event';

import CloseVc from './img/close-vc.svg';

export default {
    name: 'EcOverlay',

    components: {
        [Overlay.name]: Overlay,
        CloseVc
    },

    model: {
        prop: 'visible',
        event: 'change'
    },

    props: {
        overlayProps: {
            type: Object,
            default: () => ({})
        },

        overlayStyle: {
            type: Object,
            default: () => ({})
        },

        wrapStyle: {
            type: Object,
            default: () => ({})
        },

        visible: {
            type: Boolean,
            default: false
        },

        addZIndex: {
            type: Boolean,
            default: false
        },

        closeOnClickOverlay: {
            type: Boolean,
            default: true
        },

        lockScroll: {
            type: Boolean,
            default: true
        },

        showClose: {
            type: Boolean,
            default: false
        },

        showBusinessEvents: {
            type: Object
        },

        closeBusinessEvents: {
            type: Object
        }
    },

    computed: {
        ...mapState('baseData', [
            'brand'
        ])
    },

    watch: {
        visible(val) {
            if (val && this.showBusinessEvents) {
                EventUtil.uploadEvent(
                    this.showBusinessEvents,
                    this.$route,
                    this.brand
                );
            }
        }
    },

    methods: {
        handleClick() {
            if (this.closeOnClickOverlay) this.$emit('change', false);
        }
    }
};
</script>

<style lang="scss" scoped>
.ec-overlay {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .image {
        margin-top: 0.3rem;
    }
}

.z-index {
    z-index: 99999999;
}
</style>
