var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "delete-account" },
    [
      _c("img", {
        staticClass: "logo",
        attrs: { src: require("./img/logo.png") },
      }),
      _vm._v(" "),
      _c("div", { staticClass: "title" }, [
        _vm._v("\n        " + _vm._s(_vm.getI18n("deleteTitle")) + "\n    "),
      ]),
      _vm._v(" "),
      _c(
        "input-item",
        _vm._b(
          {
            scopedSlots: _vm._u([
              {
                key: "before",
                fn: function () {
                  return [
                    _c("div", { staticClass: "prefix" }, [
                      _vm._v("\n                +52\n            "),
                    ]),
                  ]
                },
                proxy: true,
              },
            ]),
            model: {
              value: _vm.inputInfo.value,
              callback: function ($$v) {
                _vm.$set(_vm.inputInfo, "value", $$v)
              },
              expression: "inputInfo.value",
            },
          },
          "input-item",
          _vm.inputInfo,
          false
        )
      ),
      _vm._v(" "),
      _c("cm-btn", {
        staticClass: "submit-btn",
        attrs: {
          text: _vm.getI18n("deleteSubmit"),
          disabled: _vm.inputInfo.value.length !== 10,
        },
        on: { submit: _vm.handleSubmit },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }