var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "input-item" }, [
    _c("div", { staticClass: "input-box" }, [
      _c(
        "div",
        { staticClass: "label", style: { backgroundColor: _vm.labelBg } },
        [_vm._v("\n            " + _vm._s(_vm.$t(_vm.label)) + "\n        ")]
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "input-wrap" },
        [
          _c(
            "div",
            { staticClass: "before" },
            [_vm.showBefore ? _vm._t("before") : _vm._e()],
            2
          ),
          _vm._v(" "),
          _vm.type === "textarea"
            ? _c(
                "textarea",
                _vm._b(
                  {
                    ref: "input",
                    staticClass: "input-style textarea-style",
                    style: {
                      resize: _vm.autoResizeTextarea ? "none" : "vertical",
                    },
                    attrs: {
                      id: "input",
                      rows: _vm.rows,
                      disabled: _vm.disabled,
                    },
                    on: {
                      focus: _vm.onFocus,
                      blur: _vm.onBlur,
                      input: _vm.onInput,
                    },
                  },
                  "textarea",
                  _vm.$attrs,
                  false
                )
              )
            : _vm.type === "select" || _vm.type === "cascader"
            ? [
                _c(
                  "div",
                  { staticClass: "input-style", on: { click: _vm.onClick } },
                  [
                    !_vm.labelActive
                      ? _c("span", { staticClass: "placeholder" }, [
                          _vm._v(
                            "\n                        " +
                              _vm._s(_vm.$t(_vm.label)) +
                              "\n                    "
                          ),
                        ])
                      : _c("span", [_vm._v(_vm._s(_vm.pickerLabel))]),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "van-popup",
                  {
                    attrs: {
                      "close-on-click-overlay": false,
                      position: "bottom",
                    },
                    model: {
                      value: _vm.showPopup,
                      callback: function ($$v) {
                        _vm.showPopup = $$v
                      },
                      expression: "showPopup",
                    },
                  },
                  [
                    !_vm.$slots["popup"]
                      ? [
                          _vm.type === "select"
                            ? _c(
                                "van-picker",
                                _vm._b(
                                  {
                                    ref: "picker",
                                    attrs: {
                                      "show-toolbar": "",
                                      "confirm-button-text": _vm.$t(
                                        "webview.form.confirm"
                                      ),
                                      "cancel-button-text": _vm.$t(
                                        "webview.form.cancel"
                                      ),
                                      "value-key": "label",
                                      columns: _vm.columnsData,
                                    },
                                    on: {
                                      confirm: _vm.onConfirm,
                                      cancel: _vm.onCancel,
                                    },
                                  },
                                  "van-picker",
                                  _vm.contentProps,
                                  false
                                )
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.type === "cascader"
                            ? _c(
                                "van-cascader",
                                _vm._b(
                                  {
                                    attrs: {
                                      options: _vm.columnsData,
                                      placeholder: _vm.$t(
                                        "webview.form.select"
                                      ),
                                      "active-color": "#03DA8B",
                                    },
                                    on: {
                                      close: _vm.onCancel,
                                      change: _vm.onChange,
                                      finish: _vm.onFinish,
                                    },
                                    model: {
                                      value: _vm.cascaderValue,
                                      callback: function ($$v) {
                                        _vm.cascaderValue = $$v
                                      },
                                      expression: "cascaderValue",
                                    },
                                  },
                                  "van-cascader",
                                  _vm.contentProps,
                                  false
                                )
                              )
                            : _vm._e(),
                        ]
                      : _vm._t("popup"),
                  ],
                  2
                ),
              ]
            : _c(
                "input",
                _vm._b(
                  {
                    ref: "input",
                    staticClass: "input-style",
                    attrs: {
                      id: "input",
                      type: _vm.type,
                      disabled: _vm.disabled,
                    },
                    on: {
                      focus: _vm.onFocus,
                      blur: _vm.onBlur,
                      input: _vm.onInput,
                      keyup: function ($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        ) {
                          return null
                        }
                        return _vm.onEnter.apply(null, arguments)
                      },
                    },
                  },
                  "input",
                  _vm.$attrs,
                  false
                )
              ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "after" },
            [
              _vm._t("after", function () {
                return [
                  _vm.type === "select"
                    ? _c("div", { staticClass: "arrow" })
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.clearable && _vm.value && _vm.isFocus
                    ? _c("img", {
                        staticClass: "clearable",
                        attrs: { src: require("../img/input-close.png") },
                        on: {
                          mousedown: function ($event) {
                            return _vm.handleClearable($event)
                          },
                        },
                      })
                    : _vm._e(),
                ]
              }),
            ],
            2
          ),
        ],
        2
      ),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "tip" }, [
      _vm._v("\n        " + _vm._s(_vm.errorTip || _vm.tip) + "\n    "),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }