/*
 * @Author: lijiahang
 * @Date: 2023-09-14 15:48:21
 * @Last Modified by: lijiahang
 * @Last Modified time: 2023-09-26 11:38:44
 */
import Resource from '@yqg/resource';

import {getEurekaHost} from 'easycash/common/constant/config';

const api = {
    getHost: getEurekaHost,

    upDatePhoneNumber: {
        url: '/api/user/mex/modifyMobile/verifyVerificationCodeAndPreCheck',
        method: 'post'
    },
    initMobileApply: {
        url: '/api/user/mex/modifyMobile/initMobileApply',
        method: 'post'
    },
    submitMobileNumber: {
        url: '/api/user/submitMobileNumber',
        method: 'post'
    }
};

export default Resource.create(api);
