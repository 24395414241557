<!-- @Author: wuhuiwang -->
<!-- @Date: 2024-1-31 11:18:58 -->
<!-- @Last Modified by: wangwuhui -->
<!-- @Last Modified time: 2024-01-31 11:20:20 -->

<template>
    <div
        class="btn"
        :class="{'btn-disabled': disabled}"
        :style="{
            borderRadius: '0.08rem'
        }"
        @click="handleSubmit"
    >
        {{ text }}
    </div>
</template>

<script>
export default {
    name: 'CmBtn',

    props: {
        text: {
            type: String,
            default: ''
        },

        disabled: {
            type: Boolean,
            default: false
        }
    },

    methods: {
        handleSubmit() {
            if (this.disabled) return;
            this.$emit('submit');
        }
    }
};
</script>

<style lang="scss" scoped>
@font-face {
    font-family: "HelveticaBold";
    src: url("~olacred/common/style/font-face/Helvetica-Bold.otf");
}

.btn {
    height: 0.6rem;
    background: $primary-orange;
    border-radius: 0.08rem;
    font-size: 0.18rem;
    font-family: Helvetica-Bold, Helvetica;
    font-weight: bold;
    color: #fff;
    line-height: 0.25rem;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    &-disabled {
        background: $disabled-color;
    }
}
</style>
