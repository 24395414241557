<!-- @Author: chengyuzhang -->
<!-- @Date: 2020-10-13 13:35:45.531 -->
<!-- @Last Modified by: ruiwang -->
<!-- @Last Modified time: 2023-10-12 10:36:08 -->

<template>
    <div class="vcode-input">
        <div class="v-code">
            <input
                id="code-input"
                ref="vcode"
                v-model="code"
                type="tel"
                :maxlength="codeLength"
                :disabled="telDisabled"
                @focus="handleFocus"
                @blur="handleBlur"
            >
            <label
                v-for="(item, index) in codeLength"
                :key="`code-${index}`"
                :class="{empty: codeArr[index] === undefined}"
                for="code-input"
                class="line"
                :style="{
                    color:codeArr[index] ? codeColor : dotColor,
                    width: codeWidth
                }"
                v-text="codeArr[index]"
            />
        </div>
    </div>
</template>

<script type="text/babel">
export default {
    name: 'VcodeInput',

    props: {
        codeLength: {
            type: Number,
            default: 4
        },

        codeColor: {
            type: String,
            default: '#000'
        },

        dotColor: {
            type: String,
            default: '#000'
        },

        codeWidth: {
            type: String,
            default: '40px'
        }
    },

    data() {
        return {
            code: '',
            telDisabled: false,
            focused: false
        };
    },

    computed: {
        codeArr() {
            return this.code.split('');
        }
    },

    watch: {
        code(newVal) {
            if (newVal.length > this.codeLength - 1) {
                this.telDisabled = true;
                this.$refs.vcode.blur();
                this.$emit('finish', this.code);
            }
        }
    },

    mounted() {
        setTimeout(() => {
            if (!this.$refs?.vcode) return;
            this.$refs.vcode.focus();
        }, 500);
    },

    methods: {
        init() {
            this.code = '';
            this.telDisabled = false;
            this.focused = false;
        },

        handleFocus() {
            this.focused = true;
        },

        handleBlur() {
            this.focused = false;
        }
    }};
</script>

<style lang="scss" rel="stylesheet/scss" scoped>
.vcode-input {
    .v-code {
        margin-top: 20px;
        display: -webkit-box;
        display: flexbox;
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: relative;
        width: 100%;
        margin-left: auto;
        margin-right: auto;

        input {
            position: absolute;
            left: 0.5rem;
            top: -0.7rem;
            padding: 0;
            height: 0;
            border: none;
            outline: none;
            color: transparent;
            background-color: transparent;
            caret-color: transparent;
        }

        .line {
            position: relative;
            width: 40px;
            height: 32px;
            line-height: 32px;
            text-align: center;
            font-size: 28px;
        }

        .empty::after {
            display: block;
            position: absolute;
            content: "-";
            left: 0;
            width: 100%;
            bottom: 0;
        }
    }

    @keyframes coruscate {
        0% {
            opacity: 0;
        }

        25% {
            opacity: 0;
        }

        50% {
            opacity: 1;
        }

        75% {
            opacity: 1;
        }

        to {
            opacity: 0;
        }
    }
}
</style>
