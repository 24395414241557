<!-- @Author: wuhuiwang -->
<!-- @Date: 2023-8-16 16:47:35 -->
<!-- @Last Modified by: wangwuhui -->
<!-- @Last Modified time: 2024-01-30 16:53:31 -->

<template>
    <div class="otp">
        <div class="tip">
            {{ getI18n('otpTip') }}
        </div>
        <div class="mobile-wrap">
            <input-item
                label="Número de teléfono móvil"
                :value="$route.query.mobileNumber"
                disabled
            >
                <template #before>
                    <div class="prefix">
                        +52
                    </div>
                </template>
            </input-item>
            <cm-otp
                ref="cmVcode"
                class="unsubscribe-otp"
                @input="handleFinishCode"
            />
            <cm-btn
                class="otp-btn"
                :text="getI18n('continue')"
                :disabled="code.length < 6"
                @submit="handleIdentify"
            />
        </div>
    </div>
</template>

<script>
import {debounce} from 'lodash';

import ua from 'ssr-common/util/webview/ua-parsed';

import CmBtn from 'olacred/common/component/cm-btn';
import InputItem from 'olacred/common/component/cm-form/input-item';
import CmOtp from 'olacred/common/component/cm-otp';
import maxRem from 'olacred/common/mixin/max-rem';
import Unsubscribe from 'olacred/common/resource/unsubscribe.js';

export default {
    name: 'Otp',

    title() {
        return this.getI18n('title');
    },

    components: {InputItem, CmOtp, CmBtn},

    mixins: [maxRem],

    data() {
        return {
            code: ''
        };
    },

    mounted() {
        this.$refs.cmVcode.handleSendSms();
    },

    methods: {
        getI18n(text, params) {
            return this.$t(`webview.unsubscribe.${text}`, params);
        },

        handleFinishCode(val) {
            this.code = val;
        },

        handleIdentify: debounce(async function () {
            if (this.code.length < 6) {
                return;
            }

            if (!ua.isWebview) {
                this.handleToConfirm();

                return;
            }

            const {$route: {query: {mobileNumber}}} = this;
            const data = {
                verificationCode: this.code,
                mobileNumber,
                purposeWarnCode: '40000108' // 40000108为注销用户场景
            };
            await Unsubscribe.identityAndOtp(data);
            this.handleToConfirm();
        }, 300),

        handleToConfirm() {
            const {$route: {query: {mobileNumber}}} = this;
            const params = JSON.parse(window.localStorage.getItem('reasonList') || '{}');
            if (!ua.isWebview) {
                params.verificationCode = this.code;
            }

            window.localStorage.setItem('reasonList', JSON.stringify(params));
            this.$router.push({
                name: 'unsubscribeConfirm',
                query: {mobileNumber}
            });
        }
    }
};
</script>

<style lang="scss" scoped>
@font-face {
    font-family: "HelveticaBold";
    src: url("~olacred/common/style/font-face/Helvetica-Bold.otf");
}

@font-face {
    font-family: "Helvetica";
    src: url("~olacred/common/style/font-face/Helvetica.ttf");
}

.otp {
    padding: 0.15rem 0.25rem 0.25rem;
    overflow-y: auto;
    max-width: 550px;
    margin: 0 auto;

    .tip {
        font-size: 0.14rem;
        font-family: Helvetica;
        color: #666;
        line-height: 0.21rem;
        margin-bottom: 0.15rem;
    }

    .mobile-wrap {
        .prefix {
            margin-right: 0.08rem;
        }

        ::v-deep {
            .input-style {
                &:disabled {
                    color: #333 !important;
                    -webkit-text-fill-color: #333 !important;
                }
            }
        }

        .unsubscribe-otp {
            margin-top: 0.3rem;

            ::v-deep {
                .modal-wrapper {
                    max-width: calc(550px - 0.32rem);
                }
            }
        }

        .otp-btn {
            margin-top: 0.25rem;
        }
    }
}
</style>
